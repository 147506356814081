* {
    box-sizing: border-box;
}
.btn-success {
    color: white;
}

.btn-danger {
    color: white;
}

.btn-success,
.btn-danger {
    &:focus,
    &:active,
    &:hover {
        color: white;
    }
}

.divider {
    margin: 20px 0;
    height: 1px;
    background-color: #b3b3b3;
}

.avatar-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 155px;
    width: 155px;
}

.address-wrapper {
    display: flex;
    justify-content: space-between;
}

// .left-side, .right-side{
//     width: 50%;
//     display: flex;
// }

.left-side,
.right-side {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.edit-button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.save-cancel {
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
    &.show {
        opacity: 1;
    }
    &.hide {
        opacity: 0;
    }
}

.small {
    width: 25px;
    height: 25px;
}

.social-link {
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: opacity ease 0.3s;
    &:hover {
        opacity: 0.7;
    }
}

.social-icon {
    width: 25px !important;
    height: 25px !important;
}

.active-navtab {
    background-color: rgb(221, 219, 219) !important;
    a {
        background-color: rgb(221, 219, 219) !important;
    }
}

.left-side,
.right-side {
    display: flex;
    flex-direction: column;
    width: 49%;
}

.github-picker {
    left: 150px;
    position: absolute !important;
    width: 100px !important;
    height: 100px !important;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    span {
        padding: 5px;
        height: 25px;
    }
}

.cell-text {
    margin: 0;
    word-break: break-all;
    padding: 0 !important;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.contact-cell {
    padding: 5px;
    min-height: 65px;
    width: 200px;
    border: 1px solid #9da5b1 !important;
    border-radius: 5px;
    a {
        color: #321fdb;
    }
}

.top-text {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    //width: 185px;
}

.hospital-container {
    display: flex;
    overflow: hidden;
    width: 100%;
    justify-content: flex-start;
    max-height: 50px;
    flex-wrap: wrap;
    word-wrap: break-word;
    // display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.info-table {
    @media (max-width: 569px) {
        margin-top: 20px;
        td {
            width: 200px;
            margin: 10px 0;
            a {
                width: 200px;
            }
        }
    }
}

.border-div {
    padding: 0 10px;
    border-left: 1px solid #9da5b1;
    border-right: 1px solid #9da5b1;
}

.sidebar-toggler {
    display: none !important;
}

.custom-tag {
    font-size: 13px;
    font-weight: 500;
    background: rgba(41, 226, 103, 0.3);
    p {
        color: rgb(0, 134, 45);
    }
}

// contact form birthday
.date-input {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: rgba(44, 56, 74, 0.95);
    border: 1px solid #b1b7c1;
    appearance: none;
    background-clip: padding-box;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &.disabled-input {
        &::-webkit-inner-spin-button,
        &::-webkit-calendar-picker-indicator {
            display: none;
            -webkit-appearance: none;
        }
        &:hover {
            cursor: text;
        }

        &.date-input-has-value {
            &::-webkit-datetime-edit-text,
            &::-webkit-datetime-edit-month-field,
            &::-webkit-datetime-edit-day-field,
            &::-webkit-datetime-edit-year-field {
                color: #989898;
            }
        }
    }

    // placeholder text style
    &::-webkit-datetime-edit-text,
    &::-webkit-datetime-edit-month-field,
    &::-webkit-datetime-edit-day-field,
    &::-webkit-datetime-edit-year-field {
        color: lightgray;
    }

    &.date-input-has-value {
        &::-webkit-datetime-edit-text,
        &::-webkit-datetime-edit-month-field,
        &::-webkit-datetime-edit-day-field,
        &::-webkit-datetime-edit-year-field {
            color: #2c384af2;
        }
    }
}

// contact form disabled input style
.disabled-input {
    border: none;
    color: #989898;
    border: none;
    &:hover {
        background-color: #ebedef;
    }
}

.form-select {
    &.disabled-input {
        background-image: none;
        &:hover {
            cursor: text;
        }
    }
}

// contact form accept and cancel input buttons
.buttons-wrapper {
    position: relative;

    .form-buttons {
        display: inline-block;
        position: absolute;
        right: -5px;
        top: 37px;
        z-index: 1000;
    }

    .input-buttons {
        display: flex;
        color: #989898;
        width: 35px;
        height: 30px;
        padding: 12%;
        text-align: center;
        align-items: center;
        box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
        &.accept:hover {
            background-color: #2eb85c;
            color: white;
        }
        &.cancel:hover {
            background-color: #e55353;
            color: white;
        }
    }
}

.contact-form {
    input::placeholder {
        color: lightgray;
    }

    .social-icon {
        &:hover {
            cursor: pointer;
        }
        &.disabled-icon {
            color: #9da5b1;
        }
    }

    .fellowship-specialty-wrapper {
        padding-left: 8px;
        .custom-specialty-button {
            background-color: transparent;
            border: 1px solid #9da5b1;
            color: #9da5b1;
            display: inline;
            width: 30%;
            height: 35px;
            margin: 5px;
            border-radius: 5px !important;
            transition: background-color linear 0.2s;
            &.clicked,
            &:hover {
                background-color: #2eb85c;
                border: 1px solid #2eb85c;
                color: white;
            }
            &.clicked:hover {
                background-color: #289c4f;
                border: 1px solid #289c4f;
            }
        }
    }

    .disabled-fellowship-btn {
        color: #9da5b1;
        border: 1px solid #9da5b1;
        background-color: transparent;
        padding: 0.375rem 0.75rem;
        border-radius: 0.25rem;
    }
}

.required-label::after {
    content: ' *';
    color: #d63384;
    margin-left: 2px;
}
