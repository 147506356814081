.frame_container {
    position: fixed;
    background-color: #323639;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 1000;
}
.btn-wrapper {
    position: absolute;
    top: 0;
    right: 10px;
    z-index: 1000;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.btn-close {
    cursor: pointer;
    z-index: 5;
    background-color: white;
}

.iframe-placeholder {
    text-align: center;
    position: fixed;
    display: flex;
    align-content: center;
    justify-content: center;
    top: 30px;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    z-index: 9;
    background: url('../assets/icons/200w.gif') no-repeat fixed center;
    background-size: 5%;
}

.image-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
