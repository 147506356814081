.text-hover {
    &:hover {
        text-decoration: underline !important;
    }
}

.icon-hover {
    &:hover {
        opacity: 0.3 !important;
    }
}
.nav-icon {
    width: 25px;
    height: 25px;

    &.plus {
        border: 2px solid;
        color: black;
        border-radius: 50%;
        margin-right: 5px;
        transition: opacity ease 0.3s;
        &:hover {
            opacity: 0.4;
        }
    }

    &.search {
        position: absolute;
        left: 25px;
        bottom: 6px;
        cursor: pointer;
        opacity: 0.8;
        &:hover {
            opacity: 0.5;
        }
    }
}

.hospital-name {
    &:hover {
        text-decoration: underline;
    }
    &:not(:last-of-type)::after {
        padding: 0 10px;
        content: ' | ';
    }
}

.greyed-out {
    filter: grayscale(1);
    &:hover {
        text-decoration: none !important;
    }
}
